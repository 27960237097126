import * as React from "react"

import Layout from "../components/layout"
import Success from "../components/success"
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute"

import Seo from "../components/seo"


const SuccessPage = () => (
  <Layout pageName="Success">
    <Seo title="Success" />
    <Router>
      <PrivateRoute path="/success" component={Success}/>
    </Router>
  </Layout>
)

export default SuccessPage
