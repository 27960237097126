import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import successStyles from "../assets/scss/success.module.scss";
import GridRow from './_grid-row';
import Button from '@material-ui/core/Button';






export default function success() {

    return (
        <Container maxWidth="xl">
            <Grid container spacing={3} >
                <Grid item xs={8} lg={6} >
                    <div className={successStyles.successBox}>
                        <h1 className={["h1", successStyles.title].join(" ")}>Success!</h1>
                        <p className={["body-02", successStyles.para].join(" ")} >You’ve successfully registered to our program,
                and will recieve an e-mail with the schedule and all relevant information in a few moments.</p>

                        <p className={["body-02", successStyles.para].join(" ")}>
                            Thank you for your registration, we can’t wait to play with you.</p>
                        <Button  className={["button", successStyles.Btn].join(" ")} href="#" >
                             View schedule <i class="fas fa-chevron-right"></i>
</Button>

                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
